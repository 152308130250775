import { useEffect, useState } from "react";
import BellTopBar from "../../../../Components/Bars/BellTopBar";
import PatientDetails from "./components/PatientDetails";
import useAxios from "../../../../Hooks/useAxios";
import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import LoadingModal from "../../../../Components/Modals/LoadingModal";

const Edit = () => {
  const [patient, setPatient] = useState({});
  const [visitDetail, setVisitDetail] = useState({});
  const request = useAxios();
  const { id } = useParams();

  //notes v2
  const [notesV2, setNotesV2] = useState([]);

  const [patientVitals, setPatientVitals] = useState("___");
  const [patientDetails, setPatientDetails] = useState({});
  const [prescriptions, setPrescriptions] = useState([]);
  const [investigations, setInvestigations] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [notes, setNotes] = useState([]);
  const [stepComments, setStepComments] = useState("");
  const [commenter, setCommenter] = useState("");

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  async function Initialize() {
    try {
      setLoading(true);
      let res = await request({
        method: "GET",
        url: `hospital/visit/prescription?visit_step_id=${id}`,
      });
      if (res === "error") {
        navigate("/dashboard/user-queue");
        return;
      }

      setPrescriptions(res?.visit_prescriptions || []);
      setNotes(res?.visit_notes || []);
      setPatientDetails(res?.visit_step?.visits?.patients || {});
      setPatient(res?.visit_step?.visits?.patients || {});
      setVisitDetail(res?.visit_step?.visits || {});
      setPatientVitals(res?.visit_vitals || "____");
      setPatient(res?.visit_step?.visits?.patients || {});
      setStepComments(res?.visit_step?.comments || "");
      setCommenter(
        res?.visit_step?.team_visit_steps_step_created_byToteam?.name || ""
      );
      setNotesV2(res?.notes_v2 || []);
    } catch (e) {
      //console.log("ERRor", e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    Initialize();
  }, []);
  return (
    <div className="w-full h-full bg-ghost_white">
      <div className="mb-4 pb-4">
        <BellTopBar
          label={`${patient?.first_name ? patient?.first_name : "---"} ${
            patient?.second_name ? patient?.second_name : "---"
          } - Prescription Form`}
        />
      </div>

      <div className=" ml-5 mt-5 p-5 mb-4 mr-5 bg-white rounded-lg shadow-md ">
        <PatientDetails
          patient={patient}
          setPatient={setPatient}
          patientDetails={patientDetails}
          visitDetail={visitDetail}
          patientVitals={patientVitals}
          prescriptions={prescriptions}
          notes={notes}
          setNotes={setNotes}
          setProcedures={setProcedures}
          setPrescriptions={setPrescriptions}
          setInvestigations={setInvestigations}
          setPatientDetails={setPatientDetails}
          Initialize={Initialize}
          stepComments={stepComments}
          commenter={commenter}
          notesV2={notesV2}
          setNotesV2={setNotesV2}
        />
      </div>
      <LoadingModal showModal={loading} />
    </div>
  );
};

export default Edit;
