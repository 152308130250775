import { BiLogOutCircle } from "react-icons/bi";
import BtnTemplate from "../BtnTemplate";

import Tooltip from "../../ToolTip";

import { dbs } from "../../../../../conf";

import useApp from "../../../../../Hooks/useApp";

import usePouch from "./../../../../../Hooks/usePouch";

import { useNavigate } from "react-router-dom";

import useIntialize from "../../../../../Hooks/useInitialize";

const LogOut = ({ open }) => {
  const id = "logout-tooltip";
  const { setIsLoading } = useApp();
  const navigate = useNavigate();
  const { deleteIndexes, deleteDB } = usePouch();

  const { log_out } = useIntialize();

  async function handleLogout() {
    setIsLoading(true);
    for (let i = 0; i < dbs.length; i++) {
      try {
        let db = dbs[i];

        await deleteIndexes({ db });
        await deleteDB({ db });
      } catch (e) {}
    }

    let res = await log_out();

    navigate("/");
    setIsLoading(false);
  }

  return (
    <div data-tip data-for={id} className="w-full" onClick={handleLogout}>
      <BtnTemplate label={"Logout"} open={open} active={false} click={() => {}}>
        <BiLogOutCircle />
      </BtnTemplate>
      <Tooltip id={id} txt1="Want to" txt2="logout ?">
        <BiLogOutCircle />
      </Tooltip>
    </div>
  );
};

export default LogOut;
