import React from 'react'
import { IoMdWarning } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'

export default function Queue({expired}) {

  const navigate = useNavigate()
  return (
    <div className='w-11/12 bg-white rounded-2xl p-6 my-auto shadow'>
      <div className="flex justify-end">
        <div className="text-blue cursor-pointer text-lg hover:underline" onClick={() => navigate('inventory')} >
          View &#8594;
        </div>
      </div>
      <div className="flex justify-around mx-5 my-16 gap-x-6">
        <div className='p-6 bg-gray-200 rounded-full'>
        <IoMdWarning className='text-7xl text-red  ' />
        </div>
        <div className='flex flex-col justify-around'>
          <p className="text-4xl text-start">{expired}</p>
          <p className="text-base uppercase font-semibold text-gray-500 text-start">Expired assets need to be replaced</p>
        </div>
      </div>
    </div>
  )
}
