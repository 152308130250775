import {  useEffect, useState } from 'react'

import { Table } from '../../../../../../Components/FinalTable'

import THeader from './THeader'

import TBody from './TBody'

import TInfo from './TInfo'

import TContext from './TContext'
import { TableTopBar } from '../../../../../../Components/FinalTable'

import BtnRounded from '../../../../../../Components/Buttons/BtnRounded'

import { useNavigate, useParams } from 'react-router-dom'

import useApp from '../../../../../../Hooks/useApp'

import useAxios from '../../../../../../Hooks/useAxios'

import Fuse from "fuse.js";

const LiveData = ({ showLive, setShowLive }) => {
  const [checked_id, setCheckedId] = useState(-1)
  const {id} = useParams()
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortOrder, setSordOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const [searchInput, setSearchInput] = useState("");
    const [visitId, setVisitId] = useState(0)
  const request = useAxios();
  const { setShowFormModal, setModalMessage, setShowSuccessModal, user } = useApp();
  const [docs, setDocs] = useState(0)

  useEffect(() => {
    getInitInvestigations(1, "created_at", "desc");
  }, [limit]);

  useEffect(() => {
    if (searchInput.length < 3) return;
    async function search() {
      //console.log(searchInput);
      setTableIsLoading(true);
      let res = await request({
        method: "GET",
        url: "hospital/insurance/scheme/investigations/search",
        params: {
          value: searchInput,
        },
      });
      // console.log(res);
      if (res === "network-error") {
        setShowLive(false);
        return;
      }

      if (res === "error") {
        setShowLive(false);
        return;
      }
      
      setData(res);

      setShowPagination(false);
      setIsFiltered(true);
      setTableIsLoading(false);
    }

    search();
  }, [searchInput]);

  useEffect(()=> {
    async function Initialize(){
      try {
        let res = await request({
          method: "GET",
          url: `hospital/records/consultation?visit_step_id=${id}`,
        });
        setVisitId(res.visit.id);
      } catch (error) {
        //console.log(error)
      }
      
    }

    Initialize()
  },[id, user])

  async function getInitInvestigations(
    page = 1,
    sort_field = "created_at",
    sort_order = "desc"
  ) {
    setTableIsLoading(true);
    let res = await request({
      method: "GET",
      url: "hospital/insurance/scheme/investigations",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
      },
      network_error: false,
    });

    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    setTableIsLoading(false);

    if (res === "error") return;

    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getInitInvestigations(page + 1, sortField, sortOrder);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getInitInvestigations(page - 1, sortField, sortOrder);
    }
  }

  async function handleFilterQuery(q) {
    console.log(q);
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSordOrder(order);
    setSortField(field);
    getInitInvestigations(1, field, order);
  }

  async function handleClearFilters() {
    await getInitInvestigations(1, "created_at", "desc");
    setPage(1);
    setSortField("created_at");
    setSordOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
  }


  async function Submit() {
    if (docs.length === 0) {
      setModalMessage("Select at least one Investigation");
      setShowFormModal(true);
      return;
    }
    let res = await request({
      method: "POST",
      url: "hospital/visit/investigations",
      data: 
        {
          visit_id: parseInt(visitId),
          scheme_investigation_id: parseInt(checked_id),
          requested_by: parseInt(user.id)
        }
      ,
    });

    if (res !== "error") {
      setModalMessage("Investigation Added");
      setShowSuccessModal(true);
      navigate(`/dashboard/attend/${id}`);
    }
   
  }


  return (
    <TContext.Provider
    value={{
        showLive,
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters: handleClearFilters,
        total: "...",
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
        checked_id,
        setCheckedId,
        docs,
        setDocs
    }}
    >
      <div className="px-7 pb-8">
      <TableTopBar
            mt={2}
            mb={2}
            showAdd={false}
            // AddClick={handleAdd}
            changes={0}
            handleLiveStream={() => {}}
            isFiltered={isFiltered}
            handleClearFilters={handleClearFilters}
            tableIsLoading={tableIsLoading}
            showReport={false}
            showFilter={false}
            // addLabel="Add"
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
      <Table>
        <THeader />
        <TBody />
        <TInfo />
        <div className="max-w-2xl w-full mx-auto">
        <BtnRounded mt={2} click={Submit} className="btn">
          <span className=" font-pop-reg">Add Investigation</span>
          <span className="px-3 font-normal text-2xl">
          </span>
        </BtnRounded>
      </div>
      </Table>
      </div>

      
    </TContext.Provider>
  )
}

export default LiveData