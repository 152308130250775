import { useNavigate } from "react-router-dom";
import BtnPlain from "../../../../../../../Components/Buttons/BtnPlain";
import { ActionBtn } from "../../../../../../../Components/FinalTable";

import { MdSick } from "react-icons/md";

import { FaUserEdit } from "react-icons/fa";

import useAxios from "../../../../../../../Hooks/useAxios";

import useApp from "../../../../../../../Hooks/useApp";

const TableAction = ({ doc }) => {
  const navigate = useNavigate();

  const request = useAxios();

  const { setModalMessage, setShowFormModal } = useApp();

  async function handleCheckIn() {
    let res = await request({
      method: "GET",
      url: "patient/is-checked-in",
      params: {
        patient_id: doc.id,
      },
    });

    if (res === "error") return;

    if (res.isCheckedIn) {
      setModalMessage(
        `Patient ${doc.first_name} ${doc.second_name}  is already checked in`
      );

      setShowFormModal(true);

      navigate(`view/${doc.id}`);
      return;
    }

    navigate(`check-in/${doc.id}`);
  }

  return (
    <div className="flex-1">
      {/* <TableHorizontalActionBtn /> */}
      <ActionBtn next={() => navigate(`view/${doc.id}`)} showOptions={false}>
        <div className=" gap-y-2 flex flex-col text-sm">
          {/* <BtnPlain
            activeBgColor="white"
            bg="white"
            click={() => navigate(`edit/${doc.id}`)}
          >
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="  text-black">
                <FaUserEdit />
              </p>
              <p className=" ">Edit Patient</p>
            </div>
          </BtnPlain> */}
          {/* <BtnPlain activeBgColor="white" bg="white" click={() => {}}>
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="  text-primary">
                <MdSick />
              </p>
              <p className=" ">Check In</p>
            </div>
          </BtnPlain> */}
        </div>
      </ActionBtn>
    </div>
  );
};

export default TableAction;
