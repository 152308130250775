import React, { useState, useEffect } from "react";

import BellTopBar from "../../../../Components/Bars/BellTopBar";

import { TableTopBar } from "../../../../Components/FinalTable";

import TFilter from "./Components/TableComponent/TFilter";

import AllContext from "./AllContext";

import TableAll from "./Components/TableComponent/Table";

import useAxios from "./../../../../Hooks/useAxios";

import useApp from "../../../../Hooks/useApp";

const LiveData = ({ showLive, setShowLive }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const { signal, setSignal } = useApp({});
  const [changes, setChanges] = useState(0);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState("");
  const [showPagination, setShowPagination] = useState(true);
  const [filters, setFilters] = useState({});

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const request = useAxios();

  useEffect(() => {
    getVisits(1, "created_at", "desc", filters);
  }, [limit]);

  useEffect(() => {
    const interval = setInterval(() => {
      setChanges((c) => c + 1);
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  async function getVisits(
    page = 1,
    sort_field = "created_at",
    sort_order = "desc",
    filters = {}
  ) {
    setTableIsLoading(true);
    let res = await request({
      method: "GET",
      url: "hospital/records/visits",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
        checked_in: true,
        ...filters,
      },
      network_error: false,
    });

    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    if (res === "error") return;
    setSignal({});
    setChanges(0);
    setTableIsLoading(false);
    setData(res?.docs || []);
    setTotal(res?.total_docs || "");
    setHasNextPage(res?.hasNextPage || false);
    setHasPrevPage(res?.hasPrevPage || false);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getVisits(page + 1, sortField, sortOrder, filters);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getVisits(page - 1, sortField, sortOrder, filters);
    }
  }

  async function handleFilterQuery(filters) {
    setSortField("created_at");
    setSortOrder("desc");
    setPage(1);

    getVisits(1, "created_at", "desc", filters);
    setIsFiltered(true);
    setFilters(filters);
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSortOrder(order);
    setSortField(field);
    getVisits(1, field, order);
  }

  async function handleClearFilters() {
    await getVisits(1, "created_at", "desc");
    setPage(1);
    setSortField("created_at");
    setSortOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
  }

  return (
    <AllContext.Provider
      value={{
        showLive,
        limit,
        setLimit,
        data: data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext: handleNext,
        handlePrev: handlePrev,
        handleHeaderQuery: handleHeaderQuery,
        handleFilterQuery: handleFilterQuery,
        isFiltered,
        handleClearFilters: handleClearFilters,
        total: total,
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
      }}
    >
      <div className="w-full h-screen  bg-ghost_white">
        <div className="w-full bg-ghost_white">
          <BellTopBar label="Hospital Queue" />
        </div>
        <div className="w-full px-7 pb-8">
          <TableTopBar
            FilterClick={() => setOpenFilter(true)}
            mt={2}
            mb={2}
            addLabel="Add"
            showAdd={false}
            AddClick={() => {}}
            changes={changes}
            handleLiveStream={handleClearFilters}
            isFiltered={isFiltered}
            handleClearFilters={handleClearFilters}
            tableIsLoading={tableIsLoading}
            showReport={false}
            showFilter={true}
            showSearch={false}
          />
          <TableAll />
          {openFilter && (
            <TFilter showFilter={openFilter} setShowFilter={setOpenFilter} />
          )}
        </div>
      </div>
    </AllContext.Provider>
  );
};

export default LiveData;
