import React from 'react'
import {FcDeleteDatabase} from 'react-icons/fc'

const OutOfStock = ({outOfStock}) => {
  return (
    <div className='w-full rounded-2xl bg-gradient-to-r from-rose-400 via-purple-500 to-violet-500 flex justify-between px-10'>
        <div className="flex flex-col justify-between my-20">
        <FcDeleteDatabase className='text-9xl' />
        <p className="text-3xl text-white font-semibold">Out of Stock <br /> items</p>
        </div>
        <div className="flex flex-col items-center justify-center">
            <p className="text-9xl font-semibold text-white mr-4">{outOfStock}</p>
        </div>
    </div>
  )
}

export default OutOfStock