import React, { useEffect,  useState } from "react";
import BasicModal from "../../../../../../../Components/Modals/BasicModal";

import useApp from "../../../../../../../Hooks/useApp";

import useAxios from "../../../../../../../Hooks/useAxios";

import BtnBlock from "../../../../../../../Components/Buttons/BtnBlock";

import { IoMdArrowForward } from "react-icons/io";
import usePouchCud from "../../../../../Hooks/usePouchCud";

const GivePrescription = ({
  showModal,
  setShowModal,
  doc,
  handleUpdate
}) => {
  const request = useAxios();
  const { user, setModalMessage, setShowSuccessModal } = useApp();
  const { update } = usePouchCud();
  const [currState, setCurrState] = useState("")

 
    useEffect(() => {
      async function getState(){
        if (doc.id){
        try {
          let res = await request({
            method: "GET",
            url: `hospital/visit/prescription/state?visit_prescription_id=${parseInt(doc.id)}`
            })
            let mess = res.message
            setCurrState(mess)
      } catch (error) {
          console.log(error)
      }
        }
        
      }
      getState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[doc])
 
       
 
    const submit = async () => {
      let res = await request({
          method: "PUT",
          url: `hospital/visit/prescription/status`,
          data: {
            prescription_id: parseInt(doc.id),
            prescription_status: 'complete',
            performed_by: parseInt(user.id)
          }
        });
    
        
        if (res !== "error") {
          setModalMessage("Prescription Given to Patient!");
          setShowSuccessModal(true);
          await update({name: 'visit_prescription', doc:res})
          handleUpdate()
          setShowModal(false)
        }
    }
  

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col w-full gap-y-8 p-10">
        <h2 className="font-DM-Reg text-xl text-black text-center mx-auto">
          {currState}
        </h2>
        <form className="lg:w-[517px] flex flex-col gap-y-6 mx-auto justify-center items-center">
       
          <BtnBlock click={submit} mt={2.5} bg="primary" textColor="white" className="mx-auto">
            <div className="flex text-[16px] items-center  justify-center px-2 py-1 space-x-2 ">
              Give Prescription?
              <span className="text-xl">
                <IoMdArrowForward />
              </span>
            </div>
          </BtnBlock>
        </form>
      </div>
    </BasicModal>
  );
};

export default GivePrescription;
