import {  } from '../../../../../../../Components/FinalTable';

import TableHeader from '../../../../../../../Components/FinalTable/components/TableHeader';

import HeaderBtnSort from '../../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort';
import { useState } from 'react';
import useAllRequests from '../../hooks/useAllRequests';


const Header =()=>{
    const [created_at, setCreatedAt] = useState(true)
    const [type, setType] = useState(true);
    const [qty, setQty] = useState(true);
    const [status, setStatus] = useState(true);

    const {handleHeaderQuery, handleFilterQuery, setIsFiltered, showLive } = useAllRequests();
      
    function handleDateCreatedSort() {
        if (showLive) {
            handleHeaderQuery("created_at", created_at ? "asc" : "desc");
            setCreatedAt(!created_at);
            setIsFiltered(!qty);
            return;
        }
    }

    function handleStatusSort() {
        if (showLive) {
            handleHeaderQuery("status", status ? "asc" : "desc");
            setStatus(!status);
            setIsFiltered(true);
            return;
        }
      }

    function handleTypeSort() {
        if (showLive) {
            handleHeaderQuery("created_at", qty ? "asc" : "desc");
            setType(!type);
            setIsFiltered(true);
            return;
        }
    }

    function handleQtySort() {
        if (showLive) {
            handleHeaderQuery("qty", qty ? "asc" : "desc");
            setQty(!qty);
            setIsFiltered(true);
            return;
        }
    }

   

   

    return (
        <TableHeader h={2} mt={10}>
            <HeaderBtnSort click={()=>{}} label="ITEM" w={100 /5}  />
            <HeaderBtnSort click={handleTypeSort} label="TYPE" w={100 / 5} />   
            <HeaderBtnSort click={handleQtySort} label="QUANTITY" w={100 / 5} />
            <HeaderBtnSort click={handleDateCreatedSort} label="DATE CREATED" w={100 / 5} />   
            <HeaderBtnSort click={handleStatusSort} label="STATUS" w={100 / 5} />
        </TableHeader>
        
    )
}
export default Header;