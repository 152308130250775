import React, { useState, useEffect, useContext } from "react";

import {
  Numeric,
  TextInput,
  TextArea,
} from "../../../../../../Components/Inputs/StateInput";

import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";

import useAxios from "../../../../../../Hooks/useAxios";
import useApp from "../../../../../../Hooks/useApp";

import { useParams } from "react-router-dom";

import FormBackBtn from "../../../../../../Components/Updated/FormBackBtn";

import { useNavigate } from "react-router-dom";

const NewForm = ({ listData, handleBack }) => {
  const [keys, setKeys] = useState([]);
  const [n, setN] = useState(0);
  const [prescription, setPrescription] = useState([]);

  useEffect(() => {
    const newKeys = Object.keys(listData);
    setKeys(newKeys);

    const docs = newKeys.map((key) => ({
      doc: listData[key],
      consumable_id: listData[key].id,
      dosage: "",
      no_of_day: 1,
      frequency: 1,
      qty: 1,
      food_relation: "",
      comments: "",
    }));

    setPrescription(docs);
  }, [listData]);

  return (
    <div className="h-[60%] my-auto">
      {prescription.map((doc, index) => {
        if (n == index) {
          return (
            <SingleForm
              key={index}
              doc={doc}
              n={n}
              setN={setN}
              keys={keys}
              prescription={prescription}
              setPrescription={setPrescription}
              handleBack={handleBack}
            />
          );
        }
      })}
    </div>
  );
};

const SingleForm = ({
  doc = {},
  n = 1,
  setN = () => {},
  keys = [],
  setPrescription = () => {},
  prescription = [],
  handleBack,
}) => {
  const { inventory_items } = doc.doc || {};
  const [dosage, setDosage] = useState("");
  const [frequency, setFrequency] = useState(1);
  const [no_of_day, setDays] = useState(1);
  const [food_relation, setFoodRelation] = useState("");
  const [comments, setComments] = useState("");
  const [qty, setQty] = useState(1);
  const { setModalMessage, user, setShowSuccessModal } = useApp();
  const request = useAxios();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setDosage(doc.dosage);
    setFrequency(doc.frequency);
    setDays(doc.no_of_day);
    setFoodRelation(doc.food_relation);
    setComments(doc.comments);
    setQty(doc.qty);
  }, []);

  // useEffect(() => {
  //   if (dosage === "" || frequency === "" || no_of_day === "") {
  //     return;
  //   }
  //   setQty(parseInt(dosage) * parseInt(frequency) * parseInt(no_of_day));
  // }, [dosage, frequency, no_of_day]);

  async function handleNext() {
    setN((c) => c + 1);
  }

  function handlePrevious() {
    setN((c) => c - 1);
  }

  async function handleSubmit() {
    setPrescription((c) => {
      c[n] = {
        doc: doc,
        consumable_id: doc.consumable_id,
        dosage,
        frequency,
        no_of_day,
        food_relation,
        comments,
        qty,
      };

      return c;
    });

    let data = {
      visit_step_id: parseInt(id),
      requested_by: parseInt(user.id),
      prescriptions: prescription.map((doc) => ({
        consumable_id: doc.consumable_id,
        dosage: dosage,
        no_of_day: no_of_day,
        frequency: frequency,
        qty: qty,
        food_relation: food_relation,
        comments: comments,
      })),
    };

    let res = await request({
      method: "POST",
      url: "hospital/visit/prescriptions",
      data: data,
    });

    if (res === "error") return;

    setModalMessage("Prescriptions Added");
    setShowSuccessModal(true);
    navigate(`/dashboard/attend/${id}`);
    return;
  }

  return (
    <div>
      <div className="w-full  flex flex-1 items-center justify-center ">
        <div
          style={{ width: "40%" }}
          className=" bg-ghost_white p-6 rounded-2xl"
        >
          {inventory_items && (
            <TextInput
              label="Selected Consumable"
              disabled={true}
              input={inventory_items.name}
            />
          )}

          <div className="flex gap-x-4 w-full">
            <TextInput
              label="Dosage *"
              mt={1}
              input={dosage}
              setInput={setDosage}
            />
            <Numeric
              placeholder="2"
              label="Frequency *"
              mt={1}
              input={frequency}
              setInput={setFrequency}
            />
          </div>
          <div className="flex gap-x-4 w-full">
            <Numeric
              placeholder="2"
              label="No Of Days *"
              mt={1}
              input={no_of_day}
              setInput={setDays}
            />
            {/* <Numeric
              placeholder="2"
              label="Total Qty"
              mt={1}
              input={qty}
              setInput={setQty}
            /> */}
          </div>

          <TextInput
            mt={1}
            label="Food Relation"
            input={food_relation}
            setInput={setFoodRelation}
            required={false}
          />

          <TextArea
            placeholder="Doctor Comments ... "
            label="Comments"
            mt={1}
            input={comments}
            setInput={setComments}
          />
          <div className="flex justify-between items-center mt-3">
            {n == 0 && (
              <BtnRounded bg="cadet_grey" disabled={true}>
                <span className="font-pop-reg">Back</span>
              </BtnRounded>
            )}

            {n > 0 && (
              <BtnRounded bg="primary" click={handlePrevious}>
                <span className="font-pop-reg">Back</span>
              </BtnRounded>
            )}

            {n == keys.length - 1 ? (
              <BtnRounded bg="primary" click={handleSubmit}>
                <span className="font-pop-reg">Submit</span>
              </BtnRounded>
            ) : (
              <BtnRounded bg="primary" click={() => handleNext(doc)}>
                <span className="font-pop-reg">Next</span>
              </BtnRounded>
            )}
          </div>
        </div>
      </div>
      <FormBackBtn click={handleBack} />
    </div>
  );
};

export default NewForm;
