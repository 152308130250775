import Vendors from "./components/Vendors";
import Navbar from "./components/Navbar";
import Inventory from "./components/Inventory";
import Queue from "./components/Queue";
import Assets from "./components/Assets";
import ExpensesChart from "./components/ExpensesChart";
import Requests from "./components/Requests";
import RecentRequests from "./components/RecentRequests";
import OutOfStock from "./components/OutOfStock";
import { useEffect, useState } from "react";
import useApp from "../../../../Hooks/useApp";
import useAxios from "../../../../Hooks/useAxios";
import usePouch from "../../../../Hooks/usePouch";

const Summary = () => {
  const [inventories, setInventories] = useState(0);
  const [fixed, setFixed] = useState(0);
  const [vendors, setVendors] = useState(0);
  const [expired, setExpired] = useState(0);
  const [outOfStock, setOutOfStock] = useState("");
  const [recentRequests, setRecentRequests] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [requests, setRequests] = useState([]);

  const {} = useApp();

  const request = useAxios();

  const { findAll2 } = usePouch();

  async function getInitialData() {
    let res = await request({
      method: "GET",
      url: "hospital/analytics/inventory",
    });

    // if (res === "network-error") {
    //   let other = await findAll2({ db: "inventory_data", index: ["_id"] });
    //   if (other.length === 0) return;
    //   res = other[0];
    // }

    if (res === "error") {
      return;
    }

    setRecentRequests(res.recent_requests);
    setRequests(res.requests);
    setOutOfStock(res.out_of_stock);
    setFixed(res.assigned_fixed);
    setVendors(res.total_vendors);
    setInventories(res.assigned_inventory);
    setExpired(res.expired_inventory);
    setExpenses(res.monthly_purchases);
  }

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <div className="w-full h-auto  bg-ghost_white">
      <div className="px-10 ">
        <Navbar />
        <div className="flex justify-between lg:gap-x-10 mb-10 pt-8">
          <Inventory inventories={inventories} />
          <Assets fixed={fixed} />
          <Vendors vendors={vendors} />
        </div>
        <div className="flex justify-between lg:gap-x-10 mb-10 w-full">
          <div className="w-8/12">
            <ExpensesChart expenses={expenses} />
          </div>
          <div className="w-4/12 mx-auto">
            <Queue expired={expired} />
          </div>
        </div>
        <div className="flex justify-between lg:gap-x-10 mb-10 w-full">
          <div className="w-8/12 flex justify-between lg:gap-x-10">
            <div className="w-6/12">
              <Requests requests={requests} />
            </div>
            <div className="w-6/12">
              <RecentRequests recentRequests={recentRequests} />
            </div>
          </div>
          <div className="w-4/12">
            <OutOfStock outOfStock={outOfStock} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
