const Avatar = ({ name, s = 1.8 }) => {
  function nameToAvatar(name) {
    let n = name?.split(" ") || "";
    if (n.length > 1) {
      return (
        (n[0] ? n[0][0].toUpperCase() : "") +
        (n[1] ? n[1][0].toUpperCase() : "")
      );
    }
    return n[0][0].toUpperCase();
  }

  return (
    <div
      className="bg-primary text-white font-DM-Bold flex justify-center items-center text-lg"
      style={{ borderRadius: 2000, width: `${s}em`, height: `${s}em` }}
    >
      {/* Pass the name prop to nameToAvatar */}
      {nameToAvatar(name)}
    </div>
  );
};

export default Avatar;
