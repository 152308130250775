/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { convDate } from "../../../../../Util/Time";
import useApp from "../../../../../Hooks/useApp";
import { useNavigate } from "react-router-dom";
import PatientProfile from "./Tooltips/PatientDetail";
import ViewPatient from "./Modals/ViewPatient";
import PatientVitals from "./Tooltips/PatientVitals";
import ViewVitals from "./Modals/ViewVitals";
import VisitNotesTable from "./VisitNotesTable/Table";
import PrescriptionTable from "./PrescriptionTable/Table";
import BtnPlain from "../../../../../Components/Buttons/BtnPlain";
import { FaArrowAltCircleRight } from "react-icons/fa";
import PatientHistory from "./Tooltips/PatientHistory";
import ViewComment from "./Modals/ViewComment";
import PatientComment from "./Tooltips/PatientComment";

import VisitNoteV2 from "./VisitNotesV2Table/Table";

const PatientDetails = ({
  patient,
  visitDetail,
  patientVitals,
  patientDetails,
  prescriptions,
  notes,
  setNotes,
  setPrescriptions,
  setPatientDetails,
  Initialize,
  stepComments,
  commenter,
  notesV2,
  setNotesV2,
}) => {
  const [showPatient, setShowPatient] = useState(false);
  const [showVitals, setShowVitals] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const { user } = useApp();
  const navigate = useNavigate();

  return (
    <div className="p-6">
      <p className="flex font-light text-2xl mb-8 justify-end text-gray-900">
        {convDate(visitDetail?.created_at) || ""}
      </p>
      <div className="flex justify-between mr-6 mb-20">
        <div className="space-y-4 ">
          <h2 className="font-medium text-2xl">Patient Details</h2>
          <div className="grid grid-cols-2 gap-8">
            <div>
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                GENDER
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">
                {patient?.gender}
              </p>
            </div>
            <div>
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                PHONE
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">
                {patient?.phone || ""}
              </p>
            </div>
            <div>
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                EMAIL
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">
                {patient?.email || ""}
              </p>
            </div>
            <div>
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                OCCUPATION
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">
                {patient?.occupation || ""}
              </p>
            </div>
            <div>
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                DATE OF BIRTH
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">
                {convDate(parseInt(patient?.dob)) || ""}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="">
            <h5 className="mb-2 text-xl font-medium inline-flex gap-x-2 tracking-tight text-cadet_grey ">
              DOCTOR :
              <span>
                <p className="mb-3 font-normal text-gray-700 ">
                  {user?.name || ""}
                </p>
              </span>
            </h5>
          </div>
          <div className="">
            <h5 className="mb-2 text-xl font-medium inline-flex gap-x-2 tracking-tight text-cadet_grey ">
              ROOM NO :
              <span>
                <p className="mb-3 font-normal text-gray-700 ">
                  {visitDetail?.currently_at || ""}
                </p>
              </span>
            </h5>
          </div>
          <div className="">
            <h5 className="mb-2 text-xl font-medium inline-flex gap-x-2 tracking-tight text-cadet_grey ">
              PAYMENT :
              <span>
                <p className="mb-3 inline-flex gap-x-10  font-normal text-gray-700 ">
                  {visitDetail?.default_payment_method || ""}
                  <span className="flex items-center cursor-pointer"></span>
                </p>
              </span>
            </h5>
          </div>
          <div className="">
            <h5 className="mb-2 text-xl font-medium inline-flex gap-x-2 tracking-tight text-cadet_grey ">
              VISIT ID :
              <span>
                <p className="mb-3 inline-flex gap-x-10  font-normal text-gray-700 ">
                  {visitDetail?.id || ""}
                  <span className="flex items-center cursor-pointer"></span>
                </p>
              </span>
            </h5>
          </div>
          <div className="flex flex-row gap-x-2 justify-end justify-items-end">
            <div className="flex">
              <PatientComment setShowComment={setShowComments} />
            </div>
            <div className="flex">
              <PatientVitals setShowVitals={setShowVitals} />
            </div>
            <div className="flex">
              <PatientProfile setShowPatient={setShowPatient} />
            </div>
            <div className="flex">
              <PatientHistory p_id={patient?.id || ""} />
            </div>
          </div>
        </div>
      </div>

      {/* <VisitNotesTable
        notes={notes}
        setNotes={setNotes}
        handleUpdate={Initialize}
        visitDetail={visitDetail}
      /> */}
      <VisitNoteV2
        notesV2={notesV2}
        setNotesV2={setNotes}
        handleUpdate={Initialize}
        visitDetail={visitDetail}
      />

      <PrescriptionTable
        prescriptions={prescriptions}
        setPrescriptions={setPrescriptions}
        handleUpdate={Initialize}
        visitDetail={visitDetail}
      />

      <ViewPatient
        patient={patientDetails}
        showModal={showPatient}
        setShowModal={setShowPatient}
        setPatientDetails={setPatientDetails}
      />

      <ViewVitals
        patientVitals={patientVitals}
        showModal={showVitals}
        setShowModal={setShowVitals}
      />

      <ViewComment
        stepComments={stepComments}
        showModal={showComments}
        setShowModal={setShowComments}
        commenter={commenter}
      />

      <div className="mt-[8%] mb-[3%] flex justify-evenly items-center">
        <div className="">
          <BtnPlain
            bg="primary"
            textColor="white"
            activeBgColor="secondary"
            mt={2}
            click={() => {
              navigate(`/dashboard/next-step/${visitDetail.id}`);
            }}
            className="btn"
          >
            <span className="px-2 font-light text-xl">
              <FaArrowAltCircleRight />
            </span>
            <span className=" font-pop-reg">Next Steps</span>
          </BtnPlain>
        </div>
      </div>
    </div>
  );
};

export default PatientDetails;
