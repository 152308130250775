import React, { useEffect, useState } from 'react'
import { Table } from '../../../../../../../Components/FinalTable'
import TBody from './TBody'
import THead from './THeader'
import TableContext from './TContext'

const DocumentsTable = ({documents, handleUpdate}) => {
  const [limit, setLimit] = useState(30);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);

  const [isFiltered, setIsFiltered] = useState(false);
  const [fixed, setFixed] = useState({});


  useEffect(() =>{
    async function initialize(){
      setData(documents)
    }
    initialize()
  },[])

return (
  <TableContext.Provider
  value={{
    limit,
    setLimit,
    data,
    documents,
    handleUpdate,
    setData,
    isFiltered,
    setIsFiltered,
    showPagination,
    setFixed,
  }}
>
  <div className="flex flex-col items-start bg-white p-6 rounded-lg mt-6 mb-4">
  <div className="flex flex-row justify-between w-full">
    <p className="font-DM-Med text-lg uppercase text-BLACK">
    
    </p>
    <div className="">
    </div>
   
    </div>
    <Table>
      <THead />
      <TBody />
    </Table>
  </div>
</TableContext.Provider>
)
}

export default DocumentsTable