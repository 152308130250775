import React from "react";

import Badge from "./../../../../../../Components/Badges/Badge";

import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../../Components/FinalTable";

import { convDate } from "../../../../../../Util/Time";

const TRow = ({ doc }) => {
  return (
    <TableRow>
      <TableCell w={100 / 8}>
        <TableText label={`vis-${doc?.id} || ""`} />
      </TableCell>
      <TableCell w={100 / 8}>
        <TableText label={doc?.visit_types?.name || ""} />
      </TableCell>
      <TableCell w={100 / 8}>
        <TableText label={doc?.default_payment_method || ""} />
      </TableCell>
      <TableCell w={100 / 8}>
        <TableText label={convDate(doc?.created_at) || ""} />
      </TableCell>
      <TableCell w={100 / 8}>
        <TableText
          label={`${doc?.patients?.first_name} ${doc?.patients?.second_name}`}
        />
      </TableCell>
      <TableCell w={100 / 8}>
        <Badge
          label={`${doc?.spaces_visits_currently_atTospaces?.no || ""}`}
          bg={"pale_spring_bud"}
        />
        <TableText
          mt={1}
          label={` ${doc?.spaces_visits_currently_atTospaces?.name || ""}`}
        />
        <Badge
          label={`${
            doc?.spaces_visits_currently_atTospaces?.space_types.name || ""
          }`}
          bg={"pale_pink"}
        />
      </TableCell>
      <TableCell w={100 / 8}>
        <Badge
          label={`${
            doc?.spaces_visits_from_space_idTospaces
              ? doc?.spaces_visits_from_space_idTospaces?.no
              : ""
          }`}
          bg={"pale_spring_bud"}
        />
        <TableText
          mt={1}
          label={` ${
            doc?.spaces_visits_from_space_idTospaces
              ? doc.spaces_visits_from_space_idTospaces?.name
              : ""
          }`}
        />
        <Badge
          label={`${
            doc?.spaces_visits_from_space_idTospaces
              ? doc?.spaces_visits_from_space_idTospaces?.space_types?.name
              : ""
          }`}
          bg={"pale_pink"}
        />
      </TableCell>
      <TableCell w={100 / 8}>
        <Badge
          label={`${
            doc?.spaces_visits_to_space_idTospaces
              ? doc?.spaces_visits_to_space_idTospaces?.no
              : ""
          }`}
          bg={"pale_spring_bud"}
        />
        <TableText
          mt={1}
          label={` ${
            doc?.spaces_visits_to_space_idTospaces
              ? doc?.spaces_visits_to_space_idTospaces?.name
              : ""
          }`}
        />
        <Badge
          label={`${
            doc?.spaces_visits_to_space_idTospaces
              ? doc?.spaces_visits_to_space_idTospaces?.space_types?.name
              : ""
          }`}
          bg={"pale_pink"}
        />
      </TableCell>
    </TableRow>
  );
};

export default TRow;
