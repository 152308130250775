import React, { useState, useEffect } from "react";
import Badge from "../../../../../../../Components/Badges/Badge";
import bed from "../../../../../../../Assets/Images/bed.png";

import { convDate } from "./../../../../../../../Util/Time";

import usePouch from "../../../../../../../Hooks/usePouch";

const PatientDetails = ({ patient }) => {
  const [changes, setChanges] = useState(0);

  const [current, setCurrent] = useState(false);

  const { findAll } = usePouch();

  useEffect(() => {
    async function initialize() {
      await checkInPatient();
    }
    initialize();
  }, []);

  useEffect(() => {
    if (changes === 0) {
      return;
    }

    async function sync() {
      await checkInPatient();
      setChanges(0);
    }

    sync();
  }, [changes]);

  async function checkInPatient() {
    try {
      let docs = await findAll({
        db: "visits",
        index: ["_id"],
        selector: {
          patient_id: patient.id,
          checked_out: false,
        },
        sort: [{ _id: "asc" }],
      });

      if (docs.length === 0) {
        setCurrent(false);
        return;
      }

      setCurrent(true);
    } catch (e) {}
  }

  return (
    <div className="w-full  ml-5 mt-8 mr-5 bg-white rounded-lg shadow-md ">
      <div className="mb-5 pr-6">
        <div className="font-small mt-5 p-2 grid justify-items-center text-red">
          {current && <p className="bg-rose-100 p-2 rounded-lg">In Hospital</p>}
          <Badge bg={""} textColor={""} label={""} />
        </div>
        <div className="flex flex-row gap-x-8 items-center mt-5">
          <img
            className=" ml-4 p-6"
            style={{ maxWidth: "200px", maxHeight: "200px" }}
            src={patient?.pic ? `data:image/jpeg;base64, ${patient?.pic}` : bed}
            alt="bed"
          />
          <div>
            <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
              EMAIL
            </h5>
            <p className="mb-3 font-normal text-gray-700 ">{patient?.email}</p>
            <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
              NIDA
            </h5>
            <p className="mb-3 font-normal text-gray-700 ">{patient?.id_no}</p>
          </div>
        </div>

        <div className="flex flex-col p-4 space-y-8">
          <div className="grid grid-cols-3">
            <div>
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                NAME
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">
                {`${patient?.first_name || ""} ${patient?.second_name || ""} `}
              </p>
            </div>
            <div className="">
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                PHONE
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">
                {patient?.phone || ""}
              </p>
            </div>
            <div className="">
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                WILAYA
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">
                {patient?.district || ""}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div className="">
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                SURNAME
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">
                {patient?.sir_name || ""}
              </p>
            </div>
            <div className="">
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                OCCUPATION
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">
                {patient?.occupation || ""}
              </p>
            </div>
            <div className="">
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                KATA
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">
                {patient?.ward || ""}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div className="">
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                GENDER
              </h5>
              <p className="mb-3 font-normal text-gray-700">
                {patient?.gender || ""}
              </p>
            </div>
            <div className="">
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                DATE OF BIRTH
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">
                {convDate(parseInt(patient?.dob)) || "Uknown"}
              </p>
            </div>
            <div className="">
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                NATIONALITY
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">
                {patient?.nationality || ""}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientDetails;
