import React, { useState } from "react";
import BasicModal from "../../../../../../../Components/Modals/BasicModal";

import NumberInput from "../../../../../../../Components/StateInput/NumberInput";

import BtnBlock from "../../../../../../../Components/Buttons/BtnBlock";
import { IoMdArrowForward } from "react-icons/io";

import useAxios from "../../../../../../../Hooks/useAxios";

function EditQty({ showModal, setShowModal, doc, handleUpdate }) {
  const [input, setInput] = useState("1");

  const request = useAxios();

  const submit = async () => {
    let res = await request({
      method: "PUT",
      url: "hospital/visit/prescription/edit-prescription/qty",
      data: {
        qty: input,
        id: parseInt(doc?.id) || "",
      },
    });

    if (res === "error") {
      return;
    }
    handleUpdate();
    setShowModal(false);
  };

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div
        className="flex flex-col w-full gap-y-8 p-5"
        style={{ width: "30vw" }}
      >
        <h2 className="font-DM-Reg text-xl text-black text-center">
          Update: {doc?.consumables?.inventory_items?.name || ""}
        </h2>

        <input
          className="border-2 rounded-lg w-full py-2 px-8 placeholder-cadet_grey "
          placeholder="Enter Qty"
          type="number"
          min={1}
          onChange={(e) => setInput(e.target.value)}
          value={input}
        />

        <BtnBlock click={submit} mt={2.5} bg="primary" textColor="white">
          <div className="flex w-full text-[16px] items-center justify-center px-2 py-1 space-x-2">
            <span>Update</span>
            <span className="text-xl">
              <IoMdArrowForward />
            </span>
          </div>
        </BtnBlock>
      </div>
    </BasicModal>
  );
}

export default EditQty;
