import React, { useState } from "react";
import Avatar from "./Avatar";

import Menu from "./Btns/Menu";

import SummaryBtn from "./Btns/SummaryBtn";

import Patients from "./Btns/Patients";

import HospitalQueue from "./Btns/HospitalQueue";

import ReceptionistQueue from "./Btns/ReceptionistQueue";

import VisitLogs from "./Btns/VisitLogs";

import Requests from "./Btns/Request";

import LogOut from "./Btns/LogOut";

import useApp from "../../../../Hooks/useApp";
// import SpaceQueue from "./Btns/SpaceQueue";

const SideBar = () => {
  const [open, setOpen] = useState(false);
  const { user } = useApp();
  return (
    <div
      className=" transition-all duration-500 ease-in-out h-screen shadow-md w-fit flex flex-col items-center border-r-2 gap-y-2"
      style={{}}
    >
      <Menu open={open} setOpen={setOpen} />
      <SummaryBtn open={open} />
      <Patients open={open} />
      <HospitalQueue open={open} />
      <ReceptionistQueue open={open} />
      {/* <SpaceQueue open={open} /> */}
      <VisitLogs open={open} />
      <Requests open={open} />

      <div className="flex-1"></div>
      <div
        style={{ height: "1.3px" }}
        className=" w-full bg-bright_grey mb-4"
      ></div>
      <LogOut open={open} />
      <Avatar name={user?.name || ""} />
      <div className=" mb-7"></div>
    </div>
  );
};

export default SideBar;
