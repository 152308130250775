import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import BtnPlain from "../../../../../../Components/Buttons/BtnPlain";
import { ActionBtn } from "../../../../../../Components/FinalTable";
import { FiFileText, FiFolderPlus } from "react-icons/fi";
import useAxios from "../../../../../../Hooks/useAxios";
import useApp from "../../../../../../Hooks/useApp";
import usePouchCud from "../../../../Hooks/usePouchCud";
import {
  FaPlus,
  FaRegFile,
  FaRegTrashAlt,
  FaEdit,
  FaMoneyBill,
} from "react-icons/fa";
import TableContext from "./TContext";
import VisitComments from "./Modals/VisitComments";
import VisitDocuments from "./Modals/VisitDocuments";
import ViewComments from "./Modals/ViewComments";
import ViewDocuments from "./Modals/ViewDocuments";
import GivePrescription from "./Modals/GivePrescription";
import { TbNurse } from "react-icons/tb";
import { FcCancel } from "react-icons/fc";

import EditQty from "./Modals/EditQty";
import EditAmount from "./Modals/EditAmount";

const TableActions = ({ doc }) => {
  const [showComments, setShowComments] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);
  const [showPrescriptions, setShowPrescriptions] = useState(false);
  const [showViewComments, setshowViewComments] = useState(false);
  const [showViewDocuments, setShowViewDocuments] = useState(false);
  const [showEditQTY, setShowEditQty] = useState(false);
  const [showEditAmount, setShowEditAmount] = useState(false);

  const navigate = useNavigate();
  const request = useAxios();
  const { setShowSuccessModal, setModalMessage, setShowModal, user } = useApp();
  const { prescriptions, setPrescriptions, handleUpdate } =
    useContext(TableContext);
  const { deleteDoc } = usePouchCud();

  const handleDelete = async () => {
    let res = await request({
      method: "DELETE",
      url: `hospital/visit/prescription/${doc.id}`,
    });

    if (res !== "error") {
      setModalMessage("Prescription Deleted!");
      setShowSuccessModal(true);
      await deleteDoc({ name: "visit_prescription", doc: res });
      handleUpdate();
      let newDocs = prescriptions.filter((row) =>
        row.id === doc.id ? false : true
      );
      setPrescriptions(newDocs);
    }
  };
  const handleCancel = async () => {
    let res = await request({
      method: "PUT",
      url: `hospital/visit/prescription/status`,
      data: {
        prescription_id: parseInt(doc.id),
        prescription_status: "canceled",
        performed_by: parseInt(user.id),
      },
    });

    if (res !== "error") {
      setModalMessage("Prescription Cancelled!");
      setShowSuccessModal(true);
      handleUpdate();
      setShowModal(false);
    }
  };

  return (
    <div className="flex-1">
      <EditQty
        doc={doc}
        showModal={showEditQTY}
        setShowModal={setShowEditQty}
        handleUpdate={handleUpdate}
      />
      <EditAmount
        doc={doc}
        showModal={showEditAmount}
        setShowModal={setShowEditAmount}
        handleUpdate={handleUpdate}
      />
      {showPrescriptions && (
        <GivePrescription
          doc={doc}
          showModal={showPrescriptions}
          setShowModal={setShowPrescriptions}
          prescriptions={prescriptions}
          setPrescriptions={setPrescriptions}
          handleUpdate={handleUpdate}
        />
      )}
      <VisitComments
        doc={doc}
        showModal={showComments}
        setShowModal={setShowComments}
        handleUpdate={handleUpdate}
      />
      <VisitDocuments
        doc={doc}
        showModal={showDocuments}
        setShowModal={setShowDocuments}
        handleUpdate={handleUpdate}
      />
      <ViewComments
        doc={doc.visit_comments}
        showModal={showViewComments}
        setShowModal={setshowViewComments}
        handleUpdate={handleUpdate}
      />
      <ViewDocuments
        doc={doc.visit_documents}
        showModal={showViewDocuments}
        setShowModal={setShowViewDocuments}
        handleUpdate={handleUpdate}
      />
      <ActionBtn next={() => navigate(`view/${doc.id}`)} showNext={false}>
        <div className=" gap-y-2 flex flex-col text-sm">
          <BtnPlain
            activeBgColor="white"
            bg="white"
            click={() => setShowComments(true)}
          >
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="  text-primary">
                <FaPlus />
              </p>
              <p className=" ">Add Comments</p>
            </div>
          </BtnPlain>
          <BtnPlain
            activeBgColor="white"
            bg="white"
            click={() => setShowDocuments(true)}
          >
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="  text-primary">
                <FiFolderPlus />
              </p>
              <p className=" ">Add Documents</p>
            </div>
          </BtnPlain>
          <BtnPlain
            activeBgColor="white"
            bg="white"
            click={() => setshowViewComments(true)}
          >
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="  text-primary">
                <FiFileText />
              </p>
              <p className=" ">View comments</p>
            </div>
          </BtnPlain>
          <BtnPlain
            activeBgColor="white"
            bg="white"
            click={() => setShowViewDocuments(true)}
          >
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="  text-primary">
                <FaRegFile />
              </p>
              <p className=" ">View documents</p>
            </div>
          </BtnPlain>
          <BtnPlain
            activeBgColor="white"
            bg="white"
            click={() => setShowPrescriptions(true)}
          >
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="  text-red">
                <TbNurse />
              </p>
              <p className=" ">Give Prescription</p>
            </div>
          </BtnPlain>

          {doc.prescription_status == "pending" && (
            <>
              <BtnPlain
                activeBgColor="white"
                bg="white"
                click={() => setShowEditQty(true)}
              >
                <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                  <p className="  text-red">
                    <FaEdit />
                  </p>
                  <p className=" ">Edit QTY</p>
                </div>
              </BtnPlain>
              <BtnPlain
                activeBgColor="white"
                bg="white"
                click={() => setShowEditAmount(true)}
              >
                <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                  <p className="  text-red">
                    <FaMoneyBill />
                  </p>
                  <p className=" ">Edit AMOUNT</p>
                </div>
              </BtnPlain>
              <BtnPlain activeBgColor="white" bg="white" click={handleCancel}>
                <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                  <p className="  text-orange-900">
                    <FcCancel />
                  </p>
                  <p className=" ">Cancel Prescription</p>
                </div>
              </BtnPlain>
              <BtnPlain activeBgColor="white" bg="white" click={handleDelete}>
                <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                  <p className="  text-red">
                    <FaRegTrashAlt />
                  </p>
                  <p className=" ">Delete Prescription</p>
                </div>
              </BtnPlain>
            </>
          )}
        </div>
      </ActionBtn>
    </div>
  );
};

export default TableActions;
